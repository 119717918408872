.recommend-list-page {
  min-height: 100vh;
  height: auto;
  padding: 20px;

  .recommend-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pond-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
  }
  .pond-card {
    transition: transform 0.3s, box-shadow 0.3s;
    border-radius: 8px;
    overflow: hidden;
    height: fit-content;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    .pond-card-image {
      width: 100%;
      height: 180px;
      object-fit: cover;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }

    .ant-card-meta {
      display: flex;
      align-items: center; 
      text-align: center; 
    }

    .ant-card-meta-title {
      text-align: center; 
    }
  }
}
