.home-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 100px;

  .home-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;

    .fish-art {
      width: auto;
      height: 525px;
      margin-right: 100px;
      opacity: 0;
      animation: fadeIn 1s forwards;
      animation-delay: 1s;
    }

    .welcome-text {
      color: rgb(180, 0, 0);
      font-size: 20px;
      opacity: 0;
      transform: translateX(-100%);
      animation: slideIn 0.5s forwards;
      animation-delay: 0.5s;

      .welcome-context {
        font-size: 17px;
        color: black;
        font-weight: bold;
        font-style: oblique;
      }
    }
  }
}

.content-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  .content-text {
    margin-left: 270px;
    color: rgb(180, 0, 0);
    font-size: 20px;
    opacity: 0;
    transform: translateX(-100%);
    animation: slideIn 0.5s forwards;
    animation-delay: 0.5s;

    .content-list {
      font-size: 17px;
      color: black;
      font-weight: bold;
    }
  }

  .fish-image {
    width: 625px;
    height: auto;
    margin-left: 20px;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1; 
  }
}
