.fish-list-profile-actions-button-group {
  display: flex;
  align-items: center; 
  gap: 10px; 
}

.pond-fish-list-container {
  .fish-list-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
  }
  .fish-list-card {
    transition: transform 0.3s, box-shadow 0.3s;
    border-radius: 8px;
    overflow: hidden;
    height: fit-content;

    .fish-list-card-image {
      width: 100%;
      height: 180px;
      object-fit: cover;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }
  }
  .pagination-list-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
