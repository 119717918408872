// KoiHealth.scss
.koi-health-card {
  margin-bottom: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  .ant-card-head-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }

  .add-health-button {
    display: inline-block;
    margin-bottom: 16px;
    background: #1890ff;
    color: #fff;
    border: none;
    transition: background 0.3s ease;

    &:hover {
      background: #1890ff;
    }
  }

  .ant-spin-dot {
    font-size: 18px;
    color: #1890ff;
  }
}

.health-table {
  margin-top: 16px;

  .ant-table-thead > tr > th {
    background: #e6f7ff;
    font-weight: 600;
    color: #1890ff;
  }

  .ant-table-row {
    transition: background 0.3s ease;

    &:hover {
      background: #fafafa;
    }
  }

  .ant-table-cell {
    padding: 12px 8px;
    font-size: 16px;
    color: #595959;

    &-price {
      color: #ff4d4f;
      font-weight: 500;
    }
  }
}

.add-health-modal {
  .ant-modal-title {
    font-size: 18px;
    color: #000000;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-form-item {
    margin-bottom: 16px;

    label {
      font-weight: 500;
      color: #595959;
    }

    .ant-input,
    .ant-picker {
      border-radius: 4px;
      padding: 8px;
    }
  }

  .submit-button {
    background: #1890ff;
    color: #fff;
    width: 100%;
    height: 40px;
    font-size: 16px;
    border: none;
    transition: background 0.3s ease;

    &:hover {
      background: #1890ff;
    }
  }
}
