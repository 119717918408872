// KoiInformation.scss
.koi-info-card {
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  .koi-profile__image {
    border-radius: 8px;
    width: 300px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid #ddd;
  }
  p {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .edit-btn-container {
    display: flex;
    justify-content: center;
  }

  .edit-btn {
    width: 10%;
    padding: 4px 8px;
    margin-top: 12px;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #1890ff;
    }
  }
}

.edit-modal {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-bottom: none;
    background-color: #fafafa;
  }

  .ant-modal-title {
    font-weight: 600;
  }

  .ant-modal-close {
    color: #999;
  }

  .ant-form-item-label > label {
    font-weight: 500;
  }

  .submit-btn {
    background-color: #1890ff;
    border: none;
    color: #fff;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #1890ff;
    }
  }
}
.edit-modal {
  .ant-modal-content {
    padding: 20px;
  }

  .upload-container {
    display: flex;
    gap: 10px;
    align-items: center;

    .upload-input {
      flex: 1;
    }

    .upload-progress {
      margin-left: 10px;
    }
  }

  .image-preview img {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 8px;
    margin-top: 10px;
  }
}
