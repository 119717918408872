.parameter-actions-button-group {
  display: flex;
  align-items: center; 
  gap: 10px; 
}

.ideal-parameter-example {
  display: flex; 
  justify-content: center;
  margin-bottom: 20px;
  font-size: 18px;
}

.water-parameter-profile-table {
  margin: 0 auto;
  width: 100%;
  border-spacing: 0;
  background-color: #f8f7f7;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.229);
  margin-bottom: 20px;
}

.water-parameter-profile-table-head {
  background-color: #b71c1c;
  color: white;
  font-weight: bold;
}

.water-parameter-profile-table th,
.water-parameter-profile-table td {
  padding: 12px 30px;
  border-bottom: 1px solid #f0f0f0;
}

.water-parameter-profile-table-body {
  background-color: #ffffff;
}

.water-parameter-profile-table-body th:hover {
  background-color: #ddd; 
  cursor: pointer;
}

.add-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; 

  .plus-parameter-profile-button {
    font-size: 18px; 
    padding: 12px 24px; 
    height: 70px; 
    width: 70px; 
    border: none;

    &:hover .anticon {
      color: red; 
    }
  }
}
