.koi-profile {
  padding-top: 20px;
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  &__header {
    text-align: center;
    margin-bottom: 20px;
  }

  &__image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }

  &__body {
    padding: 0 20px;
  }

  &__divider {
    color: #7cb305;
    font-weight: bold;
    font-size: 18px;
  }

  &__section {
    margin-bottom: 20px;

    &--info {
      background-color: #f9f9f9;
    }

    &--health {
      background-color: #f1f1f1;
    }

    &--record {
      background-color: #ececec;
    }
  }

  &__card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    border-radius: 8px;
  }

  &__back-button {
    display: block;
    margin: 20px auto;
  }
}
