.water-parameters-page {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #333;
  }

  .pond-selection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      margin-right: 10px;
      font-weight: bold;
      color: #333;
      font-size: 1em;
    }

    select {
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: #fff;
      font-size: 1em;
      color: #333;
      cursor: pointer;
      transition: border-color 0.3s, box-shadow 0.3s;

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      option {
        color: #333;
      }
    }
  }

  .charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;

    .chart-container {
      flex: 1 1 calc(33% - 15px);
      margin: 10px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      padding: 15px;
      text-align: center;

      h2 {
        margin-bottom: 10px;
        font-size: 1.2em;
        color: #333;
      }
    }

    .radar-chart {
      flex: 1 1 calc(100% - 15px);
      height: 600px;
    }
  }

  .warnings-list {
    list-style-type: none;
    padding: 0;

    li {
      margin: 5px 0;
      color: #d9534f;
    }
  }
}
