.signup-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/koibackground.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }
}

.signup-box {
  width: 80%;
  max-width: 900px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.logo-section {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.logo {
  width: 80%;
  height: auto;
  object-fit: contain;
}

.form-section {
  width: 60%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signup-form {
  width: 100%;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
  text-align: center;
}

.input-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  label {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #333;
  }

  input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;

    &:focus {
      border-color: #ff8c00;
    }
  }
}

.signup-button {
  width: 100%;
  padding: 12px;
  background-color: #ff8c00;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e57b00;
  }
}

.alternative-login {
  text-align: center;
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
  }

  .google-login {
    padding: 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
  }
}

.login {
  text-align: center;
  font-size: 0.9rem;

  a {
    color: #ff8c00;
    text-decoration: none;
    font-weight: bold;
  }
}
