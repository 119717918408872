.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(180, 0, 0);
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .navbar-logo {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 10px;
      font-size: 1.5rem;
      color: white;
    }

    .fish-image {
      width: 50px;
      height: auto;
    }
  }

  .navbar-links {
    display: flex;
    justify-content: center;
    flex-grow: 1;

    a {
      margin-right: 20px;
      font-size: 1rem;
      color: white;
      text-decoration: none;
      padding: 10px 15px;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #d43a3a;
      }
    }

    .ant-btn {
      margin-left: 10px;
    }
  }
}
