/* Main container */
.profile-container {
  position: relative;
  height: 100vh;
  background-color: #f3f4f6;
}

/* Background image for the profile */
.koi-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}

/* Profile content area */
.profile-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 2rem 1rem;
}

/* Form area */
.form-area {
  width: 1000px;
  height: 800px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  z-index: 1;
}

/* Sidebar for profile picture */
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  h3 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-top: 1rem;
  }
}

/* Profile picture */
.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* User details section */
h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 1rem;
}

/* Form Inputs */
.input-group {
  margin-bottom: 1.5rem;

  label {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 0.5rem;
    display: block;
  }

  input {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;

    &:focus {
      border-color: #007bff;
    }

    &::placeholder {
      color: #aaa;
    }
  }
}

/* Image upload container */
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;

  input[type="file"] {
    margin-bottom: 1rem;
  }

  .upload-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #b0c4de;
      cursor: not-allowed;
    }

    .upload-progress {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  p {
    font-size: 1rem;
    color: red;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
}

/* Form Actions (Save & Cancel buttons) */
.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  .btn-submit,
  .btn-cancel {
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .btn-submit {
    background-color: #28a745;
    color: #fff;

    &:hover {
      background-color: #218838;
    }
  }

  .btn-cancel {
    background-color: #f44336;
    color: #fff;

    &:hover {
      background-color: #e53935;
    }
  }
}

/* Edit button */
.edit-btn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .btn-edit {
    background-color: #007bff;
    color: #fff;
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
}

/* Responsiveness */
@media (max-width: 768px) {
  .profile-content {
    flex-direction: column;
    align-items: center;
  }

  .form-area {
    width: 100%;
    padding: 1.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  .input-group input {
    font-size: 1rem;
  }

  .upload-button {
    font-size: 1rem;
  }

  .form-actions {
    flex-direction: column;
    align-items: center;

    .btn-submit,
    .btn-cancel {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
