.blog-list-page {
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;

  .blog-list-container {
    display: flex;
    width: 100%;
  }

  .blog-list-container-left {
    width: 20%;
    background-color: #f0f0f0;
    padding: 20px;

    .featured-card {
      border-radius: 12px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      background-color: #fff;
    }

    .featured-text {
      padding: 15px;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    .featured-title {
      padding: 3px;
      font-size: 20px;
      font-family: "Times New Roman", Times, serif;
      display: inline-block;
      text-decoration: none;
      color: white;
      width: fit-content;

      h4 {
        margin: 0;
        line-height: 1.2;
      }

      &:hover {
        color: rgb(255, 206, 80);
      }
    }

    .featured-image {
      position: relative;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .featured-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.7),
        transparent
      ); /* Fade effect */
      color: white;
      padding: 10px;
      
      .featured-desc {
        margin: 0;
        font-size: 14px;
        font-weight: lighter;
        padding: 3px;
      }
    }

    .small-blog-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-top: 20px;
    }

    .small-blog-card {
      display: flex;
      align-items: flex-start;
      background-color: #f0f0f0;
      border-radius: 12px;
      overflow: hidden;
      height: fit-content;
    }

    .small-blog-image-container {
      width: 50%;
      height: 100px;
      overflow: hidden;
      .small-blog-image {
        width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: 12px;
        object-fit: cover;
      }
    }

    .small-blog-content {
      flex: 1;
      padding-left: 10px;
    }

    .small-blog-title {
      display: inline-block;
      text-decoration: none;
      color: black;
      width: fit-content;

      h4 {
        margin: 0;
        line-height: 1.2;
      }

      &:hover {
        color: rgb(222, 165, 20);
      }
    }

    .small-blog-description {
      font-size: 12px;
      color: #666;
      margin-top: 4px;
    }
  }

  .blog-list-container-right {
    width: 80%;
    background-color: #fff;
    padding: 20px;

    .main-blog-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .main-blog-card {
      display: flex;
      align-items: flex-start;
      background-color: #fff;
      height: fit-content;
    }

    .main-blog-img-container {
      width: 37%;
      height: 250px;
      overflow: hidden;
      margin-right: 16px;

      .main-blog-image {
        width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: 12px;
        object-fit: cover;
      }
    }

    .main-blog-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .main-blog-title {
      display: inline-block;
      text-decoration: none;
      color: black;
      width: fit-content;

      h1 {
        margin: 0;
        line-height: 1.2;
      }

      &:hover {
        color: rgb(222, 165, 20);
      }
    }

    .main-blog-description {
      color: black;
      margin: 0;
      font-weight: 400;
      font-size: larger;
    }

    .main-blog-author-container {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 0;

      .main-blog-author-extra {
        color: black;
        font-weight: 400;
        font-size: medium;
      }

      .main-blog-author {
        color: rgb(222, 165, 20);
      }
    }
  }
}
