.blog-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
}

.add-blog-btn {
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #45a049;
  }
}

.table-container {
  .ant-table {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .ant-table-thead {
    background-color: #f4f4f4;
    .ant-table-cell {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .ant-table-cell {
    padding: 16px 20px;
    font-size: 14px;
    color: #333;
  }

  .actions {
    display: flex;
    gap: 10px;

    .ant-btn-link {
      color: #007bff;
      font-size: 14px;
      transition: color 0.3s ease;
      &:hover {
        color: #0056b3;
      }
    }

    .ant-btn-danger {
      color: #f44336;
      font-size: 14px;
      transition: color 0.3s ease;
      &:hover {
        color: #c62828;
      }
    }
  }
}

.upload-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 16px;

  .upload-input {
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: #4caf50;
    }
  }

  .upload-btn {
    background-color: #4caf50;
    color: white;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }

    .MuiCircularProgress-root {
      color: white !important;
    }
  }
}

.image-preview {
  margin-top: 16px;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .submit-btn {
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }
  }
}

.ant-modal-header {
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.ant-modal-title {
  font-size: 18px;
  font-weight: bold;
}

.ant-modal-body {
  padding: 20px;
}

.ant-btn-primary {
  background-color: #4caf50;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.ant-btn-primary:hover {
  background-color: #45a049;
}
