.koi-container {
  padding: 20px;
  min-height: 100vh;
  background-color: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.my-fish-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    color: #333;
    font-size: 2rem;
    font-weight: bold;
  }

  .add-koi-button {
    background-color: rgb(180, 0, 0);
    color: white;
    height: 40px;
    border-radius: 7px;
    font-size: 16px;
    border: none;
    padding: 0 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      background-color: #b76363;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.koi-search {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .search-input {
    width: 50%;
    padding: 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;

    &:focus {
      outline: none;
      border-color: #40a9ff;
      box-shadow: 0 0 5px rgba(64, 169, 255, 0.5);
    }
  }
}

.koi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
}
.koi-card {
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 8px;
  overflow: hidden;
  height: 350px;

  .koi-card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
